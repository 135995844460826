import {
    QUESTION_LIST_REDUX,
    MOVE_TO_GLOBAL,
    BLOCK_UNBLOCK,
    RESET
} from '../../constants';
import { questionListContentDataModifiedObj } from '../../data/mappers/questionList';
import { QuestionLinkSubCategoryActions, questionListActions, questionLinkSubCategoryObj } from './types';


export const AddPublicQuestionListContent = (data: Array<questionListContentDataModifiedObj>, categoryId: string): questionListActions => ({
    type: QUESTION_LIST_REDUX.ADD_PUBLIC_QUESTION_LIST_CONTENT_DATA,
    data,
    categoryId
});

export const AddPrivateQuestionListContent = (data: Array<questionListContentDataModifiedObj>): questionListActions => ({
    type: QUESTION_LIST_REDUX.ADD_PRIVATE_QUESTION_LIST_CONTENT_DATA,
    data,
});

export const MoveToGlobalQuestion = (data: Array<questionListContentDataModifiedObj>): questionListActions => ({
    type: MOVE_TO_GLOBAL,
    data,
});

export const UpdateActiveStatusQuestion = (data: Array<questionListContentDataModifiedObj>): questionListActions => ({
    type: BLOCK_UNBLOCK,
    data,
});

export const QuestionReset = () => ({
    type: RESET
});


export const UpdatePublicQuestionListContent = (payload: questionLinkSubCategoryObj): QuestionLinkSubCategoryActions => ({
    type: "LINK_SUB_CATEGORY",
    data: payload
});
