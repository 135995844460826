import {
    USER_LIST_REDUX,
    RESET
    } from '../../constants';
import { AddUserListContentAction, UpdateActiveStatusUserAction, userListState } from './types';


export const AddUserListContent = (data: userListState): AddUserListContentAction => ({
    type: USER_LIST_REDUX.ADD_USER_LIST_CONTENT_DATA,
    data,
});


export const UpdateActiveStatusUser = (data: string): UpdateActiveStatusUserAction => ({
    type: USER_LIST_REDUX.BLOCK_UNBLOCK,
    data,
});

export const UserListReset = () => ({
    type: RESET
});
