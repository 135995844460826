import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FaqPage() {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };


  return (
    <>
      <PageStyle />
      <div className="pp__accordion">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>01</span>
            <h3>What is PatientPacket?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Think of us as your new partner to help you get the most of your medical visits. PatientPacket is an app
              designed to help you ask the right questions, keep track of notes, and organize all your appointments.
              We're here to help you feel more conﬁdent and prepared!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>02</span>

            <h3>**How will PatientPacket assist me during my doctor's visit?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              PatientPacket gives you a custom list of questions for each visit and integrates this information with your
              appointment details. You can add the visits to your calendar, print, save and share your completed
              packets and even take notes directly in the app while you're chating with your doctor.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>03</span>

            <h3>**Where do the questions in PatientPacket come from?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Our questions are thoroughly researched and updated based on the latest health info and user feedback.
              The best part? They're customized just for you, based on your appointment details, such as your
              symptoms, the type of doctor you're seeing, a medical condition or medical procedure.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>04</span>
            <h3>**Can I add my own questions?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Absolutely! We want to make sure your conversation with your doctor addresses your unique concerns
              and priorities. While we provide a great selection of questions, you can also add your own, which you
              can also submit for potential inclusion in our question database.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >

            <span>05</span>

            <h3>**How does the note-taking feature work?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We understand that remembering everything during an appointment can be a bit tricky. That's why
              we've made note-taking super easy - You can take notes by typing directly into the app or you can use
              the speech-to-text function to record notes verbally, which the app will then convert into text.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>06</span>

            <h3>**Is my information safe with PatientPacket?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes, your privacy is our utmost priority. PatientPacket is hosted on a HIPAA-compliant server to ensure
              the safety and conﬁdentiality of your information.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>07</span>
            <h3>**Does PatientPacket give medical advice or diagnoses?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              No, PatientPacket is a tool for enhancing communication with your doctor. It does not provide medical
              advice, diagnoses, or treatment.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>08</span>
            <h3>**How can I share my feedback or report an issue?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              We'd love to hear from you! Use the contact us form within the app to share any thoughts, ideas, or
              issues you've come across. Your feedback makes us beter!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span>09</span>

            <h3>**Do I need to pay to use PatientPacket?**</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              While basic features of PatientPacket are free, we also oﬀer additional features through a subscription
              plan.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>





      <div className="ppc__wrapper">
        <h3 style={{ marginLeft: "1%", marginTop: "3%" }}>Instructions:</h3>

        <ol style={{ fontWeight: 350, color: "#000" }}>
          <li>
            Enter your appointment details: Help us keep things organized by providing the appointment name,
            date, address, and contact information for the oﬃce. Your information is safe and secure with us.
          </li>

          <li>
            Tell us why you're going: We want to understand your speciﬁc concerns or reasons for the
            appointment. This allows us to suggest the most relevant and helpful questions for you.
          </li>

          <li>
            Pick your favorite questions: We'll present you with a thoughtiully chosen list of questions related to
            your reason for the appointment. Take your time and select the questions that resonate with you the
            most. This ensures that you address the topics that mater most to you during your visit.
          </li>

          <li>
            Customize your form: You know yourself best, so feel free to add any additional questions that you
            want to ask during your appointment. This way, the form becomes a personalized tool that covers all
            your important inquiries.
          </li>

          <li>
            Get your appointment form: Once you're satisﬁed with your question selection, we'll generate a
            completed patientpacket for you to have with you at your appointment. You can even print it or share it
            with a caregiver. This comprehensive patientpacket will help you remember the important questions and
            ensure you get the most out of your visit.
          </li>
        </ol>
      </div>
    </>

  );
}


function PageStyle() {
  return (
    <style>
      {`
           /* Styling the Accordion container */
           .MuiAccordion-root {
             border: none;
             box-shadow: none;
             margin-bottom: 10px;
           }
           
           /* Styling the Accordion summary */
           .MuiAccordionSummary-root {
             background-color: #f5f5f5;
             border-bottom: 1px solid #ddd;
             transition: background-color 0.3s ease;
           }
           
           .MuiAccordionSummary-root:hover {
             background-color: #ebebeb;
           }
           
           .MuiAccordionSummary-root.Mui-expanded {
             background-color: #ebebeb;
           }
           
           /* Styling the Accordion summary content */
           .MuiAccordionSummary-content {
             align-items: center;
             margin: 0;
             padding: 10px 16px;
           }
           
           /* Styling the Accordion summary text */
           .MuiAccordionSummary-content.Mui-expanded {
             margin: 0;
           }
           
           /* Styling the Accordion summary expand/collapse icon */
           .MuiAccordionSummary-expandIcon {
             color: #333;
             transition: transform 0.3s ease;
           }
           
           .MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-expandIcon {
             transform: rotate(180deg);
           }
           
           /* Styling the Accordion details container */
           .MuiAccordionDetails-root {
             padding: 16px;
             background-color: #fafafa;
             border-bottom: 1px solid #ddd;
           }
           
           .pp__accordion .MuiAccordion-root {  margin: 0;}

           .pp__accordion .MuiAccordion-root .MuiAccordionSummary-root { border-bottom: 3px solid rgba(205, 214, 218, 0.25); background: #fff; margin: 0;}
           .pp__accordion { margin: 20px;filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.05));border-radius: 6px; background-color: #fff;}
           .pp__accordion .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content span {color: #032D49;opacity: 0.3;display: block;font-weight: 700; font-size: 20px;}
           .pp__accordion .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content > h3 {color: #032D49;font-weight: 700;font-size: 15px; margin-top: 5px;}
           .pp__accordion .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper svg { background: #F3F5F6; border-radius: 50%;width: 18px;height: 18px;}
           .pp__accordion .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper svg path {fill: #062E49;}
           3:29
           .pp__accordion .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content {padding-top: 20px;padding-bottom: 16px;}
           
           .MuiCollapse-vertical p.MuiTypography-root {color: #032D49;opacity: 0.9;font-size: 14px;padding-left: 15px; padding-right: 35px;font-weight: 400; }

           .MuiAccordionSummary-content.Mui-expanded {
                margin: 0;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 10px;
                padding-bottom: 15px;
        }
        

        .pp__accordion .MuiAccordionSummary-content {
            flex-direction: column;
            align-items: flex-start;
        }
                

        `
      }
    </style>
  )
}


