import React, { useEffect, useState, useContext } from 'react';

import Box from '@mui/material/Box';

import GoToTop from '../../common/GoTop'


const TermsAndCondition = (props: any) => {
   
    return (
        <>
        <div className="ppc__wrapper">
            <h2><strong>Terms and Conditions</strong></h2>

            <p>
                Welcome to PatientPacket! These Terms and Conditions ("Terms") govern your use of the PatientPacket
                website (the "Website") and the services provided therein. By accessing and using the Website, you
                agree to comply with these Terms. Please read them carefully before using our services.
            </p>

            <h3>1. Acceptance of Terms:</h3>

            <p>
                By using the Website, you acknowledge that you have read, understood, and agreed to be bound by
                these Terms. If you do not agree to these Terms, please refrain from using the Website.
            </p>


            <h3>2. Use of the Website:</h3>

            <ul>
                <li>
                    Eligibility: You must be at least 18 years old to use the Website. By accessing or using the Website, you
                    represent and warrant that you are of legal age to enter into a binding agreement.
                </li>

                <li>
                    User Account: In order to access certain features of the Website, you may be required to create a user
                    account. You are responsible for maintaining the conﬁdentiality of your account information and agree
                    to be responsible for all activities that occur under your account.
                </li>


                <li>
                    User Responsibilities: You agree to use the Website in compliance with applicable laws and
                    regulations. You are solely responsible for any content you submit or transmit through the Website and
                    for any consequences arising from such content.
                </li>
            </ul>


            <h3>3. Privacy:</h3>

            <p>
                We respect your privacy and handle your personal information in accordance with our Privacy Policy. By
                using the Website, you consent to the collection, use, and disclosure of your information as described in
                our Privacy Policy.
            </p>
            

            <h3>4. Intellectual Property:</h3>

            <ul>

                <li>
                    Ownership: The Website and its contents, including but not limited to text, graphics, logos, images,
                    and sotiware, are the property of PatientPacket or its licensors and are protected by copyright and other
                    intellectual property laws.
                </li>

                <li>
                    Restrictions: You may not copy, modify, distribute, transmit, display, perform, reproduce, publish,
                    license, create derivative works from, transfer, or sell any information, sotiware, products, or services
                    obtained from the Website without prior writen consent from PatientPacket.
                </li>
            </ul>


            <h3>5. Disclaimer of Warranties:</h3>

            <ul>
                <li>
                    The Website is provided on an "as is" and "as available" basis. We make no warranties or
                    representations, express or implied, regarding the accuracy, reliability, or availability of the Website.
                </li>
                <li>
                    We do not guarantee that the Website will be error-free or uninterrupted, or that any defects will be
                    corrected. You use the Website at your own risk.
                </li>
            </ul>

            <h3>6. Limitation of Liability:</h3>

            <ul>
                <li>
                    To the extent permited by law, PatientPacket and its aﬃliates, directors, oﬃcers, employees, agents,
                    and suppliers shall not be liable for any direct, indirect, incidental, special, consequential, or punitive
                    damages arising out of or in connection with your use of the Website.
                </li>

                <li>
                    This limitation of liability applies regardless of the legal theory under which such damages are sought,
                    whether in contract, tort, negligence, strict liability, or otherwise.
                </li>
            </ul>

            <h3>7. Indemniﬁcation:</h3>
            <p>
                You agree to indemnify and hold harmless PatientPacket, its aﬃliates, directors, oﬃcers, employees,
                agents, and suppliers from any claims, liabilities, damages, losses, costs, or expenses (including
                reasonable atorneys' fees) arising out of or in connection with your use of the Website or any violation
                of these Terms.
            </p>


            <h3>8. Modiﬁcation or Termination:</h3>

            <p>
                We reserve the right to modify, suspend, or terminate the Website or any part thereof at any time
                without notice. We may also modify these Terms from time to time, and such modiﬁcations shall be
                eﬀective upon posting on the Website. Your continued use of the Website atier any modiﬁcations to
                these Terms indicates your acceptance of the modiﬁed Terms.
            </p>


            <h3>9. Governing Law:</h3>

            <p>
                These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without
                regard to its conﬂict of laws principles.
            </p>


            <h3>10. Severability:</h3>

            <p>
                If any provision of these Terms is deemed invalid, illegal, or unenforceable, the remaining provisions shall continue in full force and eﬀect.
            </p>


            <h3>11. Entire Agreement:</h3>

            <p>
                These Terms constitute the entire agreement between you and PatientPacket regarding your use of the
                Website and supersede any prior or contemporaneous agreements, understandings, or representations.
            </p>


            <p style={{marginTop:"2%", marginBottom:"2%", fontWeight:400}}>
                If you have any questions or concerns regarding these Terms, please contact us at support@patientpacket.com.
            </p>
{/*               
                <h3><strong>License</strong></h3>

                <p>Unless otherwise stated, Patient Packet and/or its licensors own the intellectual property rights for all material on patient-packet.com. All intellectual property rights are reserved. You may access this from patient-packet.com for your own personal use subjected to restrictions set in these terms and conditions.</p>

                <p>You must not:</p>
                <ul>
                    <li>Republish material from patient-packet.com</li>
                    <li>Sell, rent or sub-license material from patient-packet.com</li>
                    <li>Reproduce, duplicate or copy material from patient-packet.com</li>
                    <li>Redistribute content from patient-packet.com</li>
                </ul>

                <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Patient Packet does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Patient Packet,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Patient Packet shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

                <p>Patient Packet reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

                <p>You warrant and represent that:</p>

                <ul>
                    <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                    <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                    <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                    <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                </ul>

                <p>You hereby grant Patient Packet a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

                <h3><strong>Hyperlinking to our Content</strong></h3>

                <p>The following organizations may link to our Website without prior written approval:</p>

                <ul>
                    <li>Government agencies;</li>
                    <li>Search engines;</li>
                    <li>News organizations;</li>
                    <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                    <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                </ul>

                <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

                <p>We may consider and approve other link requests from the following types of organizations:</p>

                <ul>
                    <li>commonly-known consumer and/or business information sources;</li>
                    <li>dot.com community sites;</li>
                    <li>associations or other groups representing charities;</li>
                    <li>online directory distributors;</li>
                    <li>internet portals;</li>
                    <li>accounting, law and consulting firms; and</li>
                    <li>educational institutions and trade associations.</li>
                </ul>

                <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Patient Packet; and (d) the link is in the context of general resource information.</p>

                <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>

                <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Patient Packet. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

                <p>Approved organizations may hyperlink to our Website as follows:</p>

                <ul>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator being linked to; or</li>
                    <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                </ul>

                <p>No use of Patient Packet's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

                <h3><strong>iFrames</strong></h3>

                <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

                <h3><strong>Content Liability</strong></h3>

                <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

                <h3><strong>Your Privacy</strong></h3>

                <p>Please read Privacy Policy</p>

                <h3><strong>Reservation of Rights</strong></h3>

                <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

                <h3><strong>Removal of links from our website</strong></h3>

                <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

                <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

                <h3><strong>Disclaimer</strong></h3>

                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

                <ul>
                    <li>limit or exclude our or your liability for death or personal injury;</li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ul>

                <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

                <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p> */}
            </div>
            <GoToTop/>
        </>
    );
};

export default TermsAndCondition;
