import React from 'react'
import LoginForm from '../login/Form';



const AdminLogin = (props:any) => {
    return (
        <div className='login--wrapperbox max--760'>
            <h1 className='display1--text'>Sign In</h1>
            <p className='support--text m-t-10'>Welcome Back! We’ve missed you!</p>
            <LoginForm />
        </div>
    )
}


export default AdminLogin
