import { Reducer } from 'react';
import {
    SUB_Category_LIST_REDUX,
    MOVE_TO_GLOBAL_SUB,
    RESET,
    BLOCK_UNBLOCK
} from '../../constants';

import { subCategoryListActions, subCategoryListState } from './types';

export const initialState: subCategoryListState = {
    categoryId: "",
    privateSubCategorylistContent: [],
    publicSubCategorylistContent: [],
};

export const subCategoryListReducer: Reducer<subCategoryListState, subCategoryListActions> = (state = initialState, action) => {
    switch (action.type) {
        case SUB_Category_LIST_REDUX.ADD_PUBLIC_CATEGORY_LIST_CONTENT_DATA:
            return {
                ...state,
                publicSubCategorylistContent: [...(
                    //@ts-ignore
                    action?.data || [])],
                categoryId:
                    //@ts-ignore
                    action.categoryId || ""
            };

        case SUB_Category_LIST_REDUX.ADD_PRIVATE_CATEGORY_LIST_CONTENT_DATA:
            return {
                ...state,
                privateSubCategorylistContent: [...(
                    //@ts-ignore
                    action?.data || [])],
            };

        case MOVE_TO_GLOBAL_SUB:
            return {
                ...state,
                publicSubCategorylistContent: [...state.publicSubCategorylistContent, state.privateSubCategorylistContent.find(obj => obj.id === action.data)],
                privateSubCategorylistContent: state.privateSubCategorylistContent.filter(obj => obj.id !== action?.data)
            };

        case BLOCK_UNBLOCK:
            return {
                ...state,
                publicSubCategorylistContent: state.publicSubCategorylistContent.map(obj => {
                    if (obj.id === action.data) {
                        obj.isActive = !obj.isActive
                        return { ...obj }
                    };
                    return obj;
                }),
                privateSubCategorylistContent: state.privateSubCategorylistContent.map(obj => {
                    if (obj.id === action.data) {
                        obj.isActive = !obj.isActive
                        return { ...obj }
                    };
                    return obj;
                }),
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        default:
            return state;
    }
};
