import React from 'react'

export function ListIcon() {
  return (
      <>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 8C0.716667 8 0.479333 7.904 0.288 7.712C0.0960001 7.52067 0 7.28333 0 7V1C0 0.716667 0.0960001 0.479 0.288 0.287C0.479333 0.0956666 0.716667 0 1 0H7C7.28333 0 7.521 0.0956666 7.713 0.287C7.90433 0.479 8 0.716667 8 1V7C8 7.28333 7.90433 7.52067 7.713 7.712C7.521 7.904 7.28333 8 7 8H1ZM11 8C10.7167 8 10.4793 7.904 10.288 7.712C10.096 7.52067 10 7.28333 10 7V1C10 0.716667 10.096 0.479 10.288 0.287C10.4793 0.0956666 10.7167 0 11 0H17C17.2833 0 17.5207 0.0956666 17.712 0.287C17.904 0.479 18 0.716667 18 1V7C18 7.28333 17.904 7.52067 17.712 7.712C17.5207 7.904 17.2833 8 17 8H11ZM1 18C0.716667 18 0.479333 17.904 0.288 17.712C0.0960001 17.5207 0 17.2833 0 17V11C0 10.7167 0.0960001 10.479 0.288 10.287C0.479333 10.0957 0.716667 10 1 10H7C7.28333 10 7.521 10.0957 7.713 10.287C7.90433 10.479 8 10.7167 8 11V17C8 17.2833 7.90433 17.5207 7.713 17.712C7.521 17.904 7.28333 18 7 18H1ZM14 18C13.7167 18 13.4793 17.904 13.288 17.712C13.096 17.5207 13 17.2833 13 17V15H10.975C10.6917 15 10.4583 14.904 10.275 14.712C10.0917 14.5207 10 14.2833 10 14C10 13.7167 10.096 13.4793 10.288 13.288C10.4793 13.096 10.7167 13 11 13H13V10.975C13 10.6917 13.096 10.4583 13.288 10.275C13.4793 10.0917 13.7167 10 14 10C14.2833 10 14.5207 10.0957 14.712 10.287C14.904 10.479 15 10.7167 15 11V13H17.025C17.3083 13 17.5417 13.096 17.725 13.288C17.9083 13.4793 18 13.7167 18 14C18 14.2833 17.904 14.5207 17.712 14.712C17.5207 14.904 17.2833 15 17 15H15V17.025C15 17.3083 14.904 17.5417 14.712 17.725C14.5207 17.9083 14.2833 18 14 18ZM12 2V6H16V2H12ZM2 2V6H6V2H2ZM2 12V16H6V12H2Z" fill="black"/>
      </svg>
      </>
  )
}

export function CategoryIcon() {
  return (
      <>
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
        <path d="M5.275 9.00005C4.89167 9.00005 4.6 8.82938 4.4 8.48805C4.2 8.14605 4.20833 7.80838 4.425 7.47505L8.15 1.40005C8.35 1.08338 8.63333 0.925049 9 0.925049C9.36667 0.925049 9.65 1.08338 9.85 1.40005L13.575 7.47505C13.7917 7.80838 13.8 8.14605 13.6 8.48805C13.4 8.82938 13.1083 9.00005 12.725 9.00005H5.275ZM14.5 20C13.25 20 12.1873 19.5627 11.312 18.688C10.4373 17.8127 10 16.75 10 15.5C10 14.25 10.4373 13.1874 11.312 12.312C12.1873 11.4374 13.25 11 14.5 11C15.75 11 16.8127 11.4374 17.688 12.312C18.5627 13.1874 19 14.25 19 15.5C19 16.75 18.5627 17.8127 17.688 18.688C16.8127 19.5627 15.75 20 14.5 20ZM1 19.5C0.716667 19.5 0.479333 19.404 0.288 19.212C0.0960001 19.0207 0 18.7834 0 18.5V12.5C0 12.2167 0.0960001 11.979 0.288 11.787C0.479333 11.5957 0.716667 11.5 1 11.5H7C7.28333 11.5 7.521 11.5957 7.713 11.787C7.90433 11.979 8 12.2167 8 12.5V18.5C8 18.7834 7.90433 19.0207 7.713 19.212C7.521 19.404 7.28333 19.5 7 19.5H1ZM14.5 18C15.2 18 15.7917 17.7584 16.275 17.275C16.7583 16.7917 17 16.2 17 15.5C17 14.8 16.7583 14.2084 16.275 13.725C15.7917 13.2417 15.2 13 14.5 13C13.8 13 13.2083 13.2417 12.725 13.725C12.2417 14.2084 12 14.8 12 15.5C12 16.2 12.2417 16.7917 12.725 17.275C13.2083 17.7584 13.8 18 14.5 18ZM2 17.5H6V13.5H2V17.5ZM7.05 7.00005H10.95L9 3.85005L7.05 7.00005Z" fill="#000000"/>
        </svg>
      </>
  )
}


export function AppointmentIcon() {
  return (
      <>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M17 2H15V1C15 0.734784 14.8946 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0C13.7348 0 13.4804 0.105357 13.2929 0.292893C13.1054 0.48043 13 0.734784 13 1V2H7V1C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1V2H3C2.20435 2 1.44129 2.31607 0.87868 2.87868C0.316071 3.44129 0 4.20435 0 5V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V5C20 4.20435 19.6839 3.44129 19.1213 2.87868C18.5587 2.31607 17.7956 2 17 2ZM18 17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10H18V17ZM18 8H2V5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H5V5C5 5.26522 5.10536 5.51957 5.29289 5.70711C5.48043 5.89464 5.73478 6 6 6C6.26522 6 6.51957 5.89464 6.70711 5.70711C6.89464 5.51957 7 5.26522 7 5V4H13V5C13 5.26522 13.1054 5.51957 13.2929 5.70711C13.4804 5.89464 13.7348 6 14 6C14.2652 6 14.5196 5.89464 14.7071 5.70711C14.8946 5.51957 15 5.26522 15 5V4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V8Z" fill="black"/>
        </svg>
      </>
  )
}



