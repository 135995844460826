import {
    SUB_Category_LIST_REDUX,
    MOVE_TO_GLOBAL_SUB,
    BLOCK_UNBLOCK,
    RESET
} from '../../constants';
import { subCategoryListContentDataModifiedObj } from '../../data/mappers/subCategoryList';
import { subCategoryListActions } from './types';


export const AddPublicSubCategoryListContent = (data: Array<subCategoryListContentDataModifiedObj>, categoryId: string): subCategoryListActions => ({
    type: SUB_Category_LIST_REDUX.ADD_PUBLIC_CATEGORY_LIST_CONTENT_DATA,
    data,
    categoryId
});

export const AddPrivateSubCategoryListContent = (data: Array<subCategoryListContentDataModifiedObj>): subCategoryListActions => ({
    type: SUB_Category_LIST_REDUX.ADD_PRIVATE_CATEGORY_LIST_CONTENT_DATA,
    data,
});


export const MoveToGlobalSubCategory = (data: Array<subCategoryListContentDataModifiedObj>): subCategoryListActions => ({
    type: MOVE_TO_GLOBAL_SUB,
    data,
});

export const UpdateActiveStatusSubCategory = (data: Array<subCategoryListContentDataModifiedObj>): subCategoryListActions => ({
    type: BLOCK_UNBLOCK,
    data,
});

export const SubCategoryReset = () => ({
    type: RESET
});
