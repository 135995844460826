import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { TextField, PasswordField } from '../common/TextField';

import { Form, Field } from 'react-final-form'

import { userLogin } from '../../data/mappers/user';

import { postRequest, isApiCodeSucess } from '../../data/helpers/services';
import { ADMIN_LOGIN } from '../../constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { HideProgressBar, ShowProgressBar, ShowSuccessAlert } from '../../store/alerts';
import Cookies from 'js-cookie';


const LoginForm = (props: any) => {
    const { submitting } = props;

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const required = (value: any) => (value ? undefined : 'This field is required')

    const signin = async (values: any) => {
        let data = userLogin(values);
        var response: any = null;

        dispatch(ShowProgressBar());
        response = await postRequest((ADMIN_LOGIN), data, "");

        if (response) {
            dispatch(HideProgressBar());

            if (isApiCodeSucess(response)) {
                try {
                    // After receiving the access token from the server
                    Cookies.set('access_token', response.data.data.authorization.access_token, {
                        expires: 7, // in days
                        secure: true,
                        // httpOnly: true,
                    });

                    Cookies.set('refresh_token', response.data.data.authorization.refresh_token, {
                        expires: 7, // in days
                        secure: true,
                        // httpOnly: true,
                    });

                    dispatch(ShowSuccessAlert({ visible: true, message: "Logged in successfully." }));
                    navigate('/admin/dashboard');

                } catch (error) {
                    console.error(error);
                }
            }
        }
    };


    return (
        <>
            <Box className='mx--auto'>
                <Form onSubmit={signin}>
                    {
                        (props: any) => (
                            <form onSubmit={props.handleSubmit}>
                                <Box component="div" className="row m-t-30">
                                    <div className='col-12 md-col-12'>
                                        <label className='input--label m-b-10' htmlFor="Email">Email</label>
                                        <Field validate={required} name="email" component={TextField} type="text" placeholder='Enter registered email here'
                                            styleName='input--transparent' />
                                    </div>

                                    <div className='col-12 md-col-12 m-t-20'>
                                        <label className='input--label m-b-10' htmlFor="Password">Password</label>
                                        <Field validate={required} name="password" component={PasswordField} type="password" placeholder='Enter password here'
                                            styleName='input--transparent' />

                                    </div>

                                    <div className="col-12 m-t-30">
                                        <button type="submit" className={`btn--pill saveBtn w-100 w-md-auto`} disabled={submitting}>
                                            {showSubmitButtonLabel(submitting)}
                                        </button>
                                    </div>
                                </Box>
                            </form>
                        )
                    }
                </Form>
            </Box>
        </>
    );
};

const showSubmitButtonLabel = (submitting: boolean) => {
    return `Sign In`;
};

export default LoginForm;
