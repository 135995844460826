import React from 'react'

export default function ErrorLabel(props) {
  return (
    <>
        <span className={`error--pill ${props.styleName}`}>
            {props.children}
        </span>
    </>
  )
}
