export const APPOINTMENT_REDUX = {
    ADD_NEW_APPOINTMENT_TO_LIST_CONTENT_DATA: "ADD_NEW_APPOINTMENT_TO_LIST_CONTENT_DATA",
    ADD_APPOINTMENT_LIST_CONTENT_DATA: "ADD_APPOINTMENT_LIST_CONTENT_DATA"
}

export const RESET = 'RESET';
export const MOVE_TO_GLOBAL = 'MOVE_TO_GLOBAL';
export const MOVE_TO_GLOBAL_SUB = 'MOVE_TO_GLOBAL_SUB';

export const Category_LIST_REDUX = {
    ADD_CATEGORY_LIST_CONTENT_DATA: 'ADD_CATEGORY_LIST_CONTENT_DATA',
    BLOCK_UNBLOCK: 'BLOCK_UNBLOCK'
}

export const BLOCK_UNBLOCK = 'BLOCK_UNBLOCK';

export const SUB_Category_LIST_REDUX = {
    ADD_PUBLIC_CATEGORY_LIST_CONTENT_DATA: 'ADD_PUBLIC_CATEGORY_LIST_CONTENT_DATA',
    ADD_PRIVATE_CATEGORY_LIST_CONTENT_DATA: 'ADD_PRIVATE_CATEGORY_LIST_CONTENT_DATA',
}

export const QUESTION_LIST_REDUX = {
    ADD_PUBLIC_QUESTION_LIST_CONTENT_DATA: 'ADD_PUBLIC_QUESTION_LIST_CONTENT_DATA',
    ADD_PRIVATE_QUESTION_LIST_CONTENT_DATA: 'ADD_PRIVATE_QUESTION_LIST_CONTENT_DATA',
}

export const USER_LIST_REDUX = {
    ADD_USER_LIST_CONTENT_DATA: 'ADD_USER_LIST_CONTENT_DATA',
    BLOCK_UNBLOCK: 'BLOCK_UNBLOCK'
}


export const ALERTS_REDUX = {
    SHOW_ERROR_ALERT: 'SHOW_ERROR_ALERT',
    SHOW_DIALOG_BOX: 'SHOW_DIALOG_BOX',
    SHOW_SUCCESS_ALERT: 'SHOW_SUCCESS_ALERT',
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
    SHOW_BACKDROP: 'SHOW_BACKDROP',
    HIDE_BACKDROP: 'HIDE_BACKDROP',
};

