import { Reducer } from 'react';
import {
    APPOINTMENT_REDUX,
    RESET
    } from '../../constants';

import { appointmentListActions, appointmentListState } from './types';

export const initialState: appointmentListState = {
    appointmentListContent: [],
    currentPage: 0,
    searchValue: "",
    userId: ""
};

//@ts-ignore
export const appointmentListReducer: Reducer<appointmentListState, appointmentListActions> = (state = initialState, action: any) => {
    switch (action.type) {
        case APPOINTMENT_REDUX.ADD_APPOINTMENT_LIST_CONTENT_DATA:
            return {
                ...state,
                currentPage: action?.data?.currentPage ? action.data?.currentPage : 0,
                searchValue: action?.data?.searchValue ? action.data?.searchValue : "",
                userId: action?.data?.userId ? action.data?.userId : "",
                appointmentListContent: [...(action?.data?.appointmentListContent || [])],
            };

        case APPOINTMENT_REDUX.ADD_NEW_APPOINTMENT_TO_LIST_CONTENT_DATA:
            const existing_appointmentListContent = [...state.appointmentListContent]
            existing_appointmentListContent.unshift(action?.data)

            return {
                ...state,
                appointmentListContent: existing_appointmentListContent,
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        default:
            return state;
    }
};
