import { Avatar, Drawer, Grid, List, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from "@mui/system";

import {
    NavLink
} from "react-router-dom";
import { useDispatch } from "react-redux";

import { ListIcon, CategoryIcon, AppointmentIcon } from "../icons/AdminIcon";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import { UserListReset } from '../../store/userList';
import { CategoryReset } from '../../store/categoryList';
import { AppointmentResetAction } from '../../store/appointmentList';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { blue } from "@mui/material/colors";


const sidebarStyle = {
    drawer: {
        display: 'flex',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            alignItems: 'center',
        },
    },
    logo: {
        width: '95px',
        height: 'auto',
        textAlign: 'center',
        alignItems: 'center',
        maxHeight: { xs: 233, md: 167 },
        maxWidth: { xs: 350, md: 250 },
        marginTop: "40px"
    },
    menusList: {
        '& .MuiListItem-root:hover': {
            background: 'linear-gradient(132.14deg, #F89E24 6.93%, #F6413C 90.47%)'
        }
    },
    profilePicture: {
        minWidth: '70px',
        minHeight: '70px',
        maxWidth: '95px',
        maxHeight: '100px',
        textAlign: 'center',
    }
}

const SidebarLayout = (props: any) => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    let menuList = [
        {
            text: "Dashboard",
            icon: <ListIcon />,
            url: "/admin/dashboard"
        },
        {
            text: "Categories",
            icon: <CategoryIcon />,
            url: "/admin/categories"
        },
        {
            text: "Appointments",
            icon: <AppointmentIcon />,
            url: "/admin/appointments"
        },
        {
            text: "Users",
            icon: <PersonOutlineOutlinedIcon  style={{fill: "black" }} />,
            url: "/admin/users"
        },
    ]
    
    function handleStore() {
        Cookies.remove('access_token');
        Cookies.remove('refresh_token');
        dispatch(UserListReset())
        dispatch(CategoryReset())
        dispatch(AppointmentResetAction())
        navigate('/');
        return ""
    }

    return (
        <Grid>
            <Drawer
                sx={sidebarStyle.drawer}
                variant="permanent"
                anchor="left"
                >
                <>
                <Box className='menuItem--list'>
                    <Box className='brandlogo'>
                        <NavLink to={menuList[0].url}>
                            <img src="/images/pp-logo.svg" className="max-w-180" alt="PatientPacket-logo" />
                        </NavLink>
                    </Box>
                </Box>
                <Box className='creator__side'>
                    <Box className='menuItem--list'>
                    <List className='' sx={sidebarStyle.menusList}>

                        {menuList.map((item, index) => {
                            return (
                                <NavLink onClick={()=> {props.handleDrawerToggle()}} to={item.url} key={index} className={({ isActive }) =>
                                isActive ? 'sidebar-link active' : 'sidebar-link'
                            }>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText sx={{margin: '0px'}} primary={item.text} />
                                </NavLink>
                            );
                        })}
                    </List>
                    </Box>
                </Box>
                <Box className='avatar--logo'>

                    <Avatar
                        sx={sidebarStyle.profilePicture}
                        alt={"admin"}
                        src={""}
                        variant="square"
                        className="avator--img"
                        />
                    <Box className="text--dark" component="p" sx={{ paddingTop: '10px' }}>👋 <span className="ms--1 fw--400">Hello,</span> <b>{"admin"}</b></Box>
                    <Box className='m-t-30'>
                        <button className='saveBtn shrinkbtn' onClick={() => handleStore()}>Log Out</button>
                    </Box>
                </Box>
                </>
            </Drawer>
        </Grid>
    );
}

export default SidebarLayout;
