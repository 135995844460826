import { combineReducers } from 'redux';

import {alertsReducer} from "./alerts";
import {appointmentListReducer} from "./appointmentList";
import {categoryListReducer} from "./categoryList";
import { userListReducer } from "./userList";
import { subCategoryListReducer } from "./subCategoryList";
import { questionListReducer } from "./questionList";


const rootReducer = combineReducers({
    alerts: alertsReducer,
    appointmentList: appointmentListReducer,
    categoryList: categoryListReducer,
    userList: userListReducer,
    subCategoryList: subCategoryListReducer,
    questionList: questionListReducer,

});

export default rootReducer;