import LeftBarContainer from '../common/LeftBar.Component'
import AdminLogin from '../common/AdminLoginForm'
import Box from '@mui/material/Box';


const SignIn = (props: any) => {
   return (
        <section aria-label='signIn-section' className='h-inherit'>
          <div className='authentication__wrapper h-inherit grid__container grid-md-1-2-column'>
              <LeftBarContainer />
              <div className='login__innerarea justify-content-loginstart'>
                <Box sx={{ width: '100%' }}>
                  <AdminLogin />
                </Box>
              </div>
          </div>
        </section>
      )
    }

    export default SignIn;
