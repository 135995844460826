import { Reducer } from 'react';
import {
    Category_LIST_REDUX,
    RESET
    } from '../../constants';

import { categoryListActions, categoryListState } from './types';

export const initialState: categoryListState = {
    categorylistContent: [],
    currentPage: 0,
    searchValue: "",
};

export const categoryListReducer: Reducer<categoryListState, categoryListActions> = (state = initialState, action:any) => {
    switch (action.type) {
        case Category_LIST_REDUX.ADD_CATEGORY_LIST_CONTENT_DATA:
            return {
                ...state,
                categorylistContent: [...(action.data.categorylistContent || [])],
                searchValue: action?.data?.searchValue ? action.data?.searchValue : "",
                currentPage: action?.data?.currentPage ? action.data?.currentPage : 0,
            };
        
        case Category_LIST_REDUX.BLOCK_UNBLOCK:
            return {
                ...state,
                categorylistContent: state.categorylistContent.map(obj => {
                    if (obj.id === action.data) {
                        obj.isActive = !obj.isActive
                        return {...obj}
                    };
                    return obj;
                }),
            };
    
        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        default:
            return state;
    }
};
