import { Reducer } from 'react';
import {
    USER_LIST_REDUX,
    RESET
    } from '../../constants';

import { userListActions, userListState } from './types';

export const initialState: userListState = {
    userlistContent: [],
    currentPage: 0,
    searchValue: "",
};

export const userListReducer: Reducer<userListState, userListActions> = (state = initialState, action:any) => {
    switch (action.type) {
        case USER_LIST_REDUX.ADD_USER_LIST_CONTENT_DATA:
            return {
                ...state,
                userlistContent: [...(action.data.userlistContent || [])],
                searchValue: action?.data?.searchValue ? action.data?.searchValue : "",
                currentPage: action?.data?.currentPage ? action.data?.currentPage : 0,
            };

        case USER_LIST_REDUX.BLOCK_UNBLOCK:
            return {
                ...state,
                userlistContent: state.userlistContent.map(obj => {
                    if (obj.id === action.data) {
                        obj.blockedUser = !obj.blockedUser
                        return {...obj}
                    };
                    return obj;
                }),
            };

                
        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        default:
            return state;
    }
};
