import { Reducer } from 'react';
import {
    QUESTION_LIST_REDUX,
    MOVE_TO_GLOBAL,
    BLOCK_UNBLOCK,
    RESET
} from '../../constants';

import { questionListActions, questionListState } from './types';

export const initialState: questionListState = {
    categoryId: "",
    privateQuestionlistContent: [],
    publicQuestionlistContent: [],
};

export const questionListReducer: Reducer<questionListState, questionListActions> = (state = initialState, action) => {
    switch (action.type) {
        case QUESTION_LIST_REDUX.ADD_PUBLIC_QUESTION_LIST_CONTENT_DATA:
            return {
                ...state,
                publicQuestionlistContent: [...(
                    //@ts-ignore
                    action?.data || [])],
                categoryId:
                    //@ts-ignore
                    action.categoryId || ""
            };

        case QUESTION_LIST_REDUX.ADD_PRIVATE_QUESTION_LIST_CONTENT_DATA:
            return {
                ...state,
                privateQuestionlistContent: [...(
                    //@ts-ignore
                    action?.data || [])],
            };

        case MOVE_TO_GLOBAL:
            const QuestionObj = state.privateQuestionlistContent.find(
                obj => obj.id === action.data
            )
            if (QuestionObj) {
                QuestionObj.sub_category_id = []
            }
            return {
                ...state,
                publicQuestionlistContent: [...state.publicQuestionlistContent, QuestionObj],
                privateQuestionlistContent: state.privateQuestionlistContent.filter(obj => obj.id !== action?.data)
            };

        case BLOCK_UNBLOCK:
            return {
                ...state,
                publicQuestionlistContent: state.publicQuestionlistContent.map(obj => {
                    if (obj.id === action.data) {
                        obj.isActive = !obj.isActive
                        return { ...obj }
                    };
                    return obj;
                }),
                privateQuestionlistContent: state.privateQuestionlistContent.map(obj => {
                    if (obj.id === action.data) {
                        obj.isActive = !obj.isActive
                        return { ...obj }
                    };
                    return obj;
                }),
            };

        case RESET:
            return {
                ...initialState //Always return the initial state
            };

        case "LINK_SUB_CATEGORY":
            //@ts-ignore
            const questionId = action?.data?.questionId
            //@ts-ignore
            const sub_category_id = action?.data.subCategoryIds

            return {
                ...state,
                publicQuestionlistContent: state.publicQuestionlistContent.map(obj => {
                    if (obj.id === questionId) {
                        obj.sub_category_id = sub_category_id
                        return { ...obj }
                    };
                    return obj;
                }),
            }

        default:
            return state;
    }
};
