export const ADMIN_LOGIN = "signin/";
export const DASHBOARD = "dashboard/analysis/"

export const CATEGORY = {
    LIST: "category/list/",
    CREATE: "category/create/",
    EDIT: "category/edit/",
    STATUS_UPDATE: "category_active/status/",
    DELETE: "category/delete/",
    BULK_CREATE: "category/bulk/create/",

}

export const SUB_CATEGORY = {
    LIST: "sub-category/list/",
    CREATE: "sub-category/create/",
    EDIT: "sub-category/edit/",
    MAKE_GLOBAL: "sub-category/global/",
    STATUS_UPDATE: "sub_category_active/status/",
    BULK_CREATE: "sub-category/bulk/create/"
}

export const QUESTION = {
    LIST: "question/list/",
    CREATE: "question/create/",
    EDIT: "question/edit/",
    MAKE_GLOBAL: "question/global/",
    STATUS_UPDATE: "question/status/",
    BULK_CREATE: "question/bulk/create/",
    LINK: "question/subcategory/link/"
}

export const APPOINTMENT = {
    ALL_APPOINTMENT_LIST: "appointments/",
    DETAIL: "appointment/detail/"
}

export const USER_API = {
    LIST: "users/list/",
    BLOCK: "users/block/",
    DETAIL: "users/detail/",
    APPOINTMENT_LIST: "user/appointment/list/"
}