import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import packageJson from '../package.json';
import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material';


const version = packageJson.version;


export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#232157',
    },
    secondary: {
      main: '#F6433B',
    },
    background: {
      paper: '#fff',
      default: '#f8f8f9',
    },
    text: {
      primary: 'rgba(255,255,255,0.87)',
      secondary: '#C2C1E6',
      disabled: '#C2C1E6'
    },
    mode: 'dark'
  },
  typography: {
    allVariants: {
      fontFamily: 'Lexend Deca',
      textTransform: 'none',
      fontSize: 15,
      fontWeight: 300,
      color: 'var(--bg--dark)'
    },
  }
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme(themeOptions);


root.render(
  <>
    {/* <React.StrictMode> */}
    {/*// @ts-ignore */}
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CacheBuster>
    {/* </React.StrictMode> */}
  </>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
