import Axios, { AxiosResponse } from 'axios';
import { BASE_PATH } from '../../constants';
import { store } from '../../store';
import { HideProgressBar, ShowErrorAlert } from '../../store/alerts';
// import { userLoggedOut } from '../../store/user';


export const postRequest = (endpoint: string, body: any, token?: string) => {
    return Axios.post(getApiPath(endpoint), JSON.stringify(body), getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
};

export const postFileRequest = (endpoint: string, body: any, token?: string) => {
    return Axios.post(getApiPath(endpoint), body, getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
};

export const putRequest = (endpoint: string, body: any, token?: string) => {
    return Axios.put(getApiPath(endpoint), JSON.stringify(body), getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
};

export const getRequest = (endpoint: string, token?: string) => {
    return Axios.get(getApiPath(endpoint), getConfigSetting(token ?? '')).catch(function (error: any) {
        handleErrorResponse(error);
    });
};

const getApiPath = (endpoint: string) => BASE_PATH+'/api/admin/v1/' + endpoint;

const getConfigSetting = (token: string) => {
    let headers: any = {
        'content-type': 'application/json',
    };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return {
        headers: headers,
    };
};

const handleErrorResponse = (error: any) => {
    const { dispatch } = store;
    if (error.response.status == 401) {
        // dispatch(userLoggedOut());
        dispatch(ShowErrorAlert({visible:true, message:error.response.data.message}));
    }

    if (error.code === "ERR_NETWORK") {
        dispatch(ShowErrorAlert({visible:true, message:"Internet connection lost"}));
    }

    if(error.response.data.code === 400 && error.response.data.message !== '') {
        dispatch(ShowErrorAlert({visible:true, message:error.response.data.message}));
    } else {
        dispatch(ShowErrorAlert({visible:true, message: error.message}));
        console.log(error);
    }
    dispatch(HideProgressBar());

};

export const isApiCodeSucess = (resp: AxiosResponse) => {
    const { dispatch } = store;

    if (resp?.data?.code == 200){
        return resp?.data?.code == 200;
    }else if (resp?.data?.code == 400){
        dispatch(ShowErrorAlert({visible:true, message: resp.data.message}));
        return false;
    }else if (resp?.data?.code == 500){
        dispatch(ShowErrorAlert({visible:true, message: "Backend : " +resp.data.message}));
        return false;
    }
};
