import { Box } from "@mui/material";
import React,{ Suspense } from "react";
import SidebarLayout from "./SidebarLayout";
import {
    Routes,
    Route,
} from "react-router-dom";
import { PrivateRoute } from "../../App";
import MenuIcon from '@mui/icons-material/Menu';

//Responsive Drawer
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { useMediaQuery } from "@mui/material";

const UserDetail = React.lazy(() => import('../pages/UserDetail'));
const AppointmentDetail = React.lazy(() => import('../pages/AppointmentDetail'));
const QuestionList = React.lazy(() => import('../pages/QuestionList'));
const SubCategoryList = React.lazy(() => import('../pages/SubCategoryList'));
const UserList = React.lazy(() => import('../pages/UserList'));
const Appointment = React.lazy(() => import('../pages/Appointment'));
const Category = React.lazy(() => import('../pages/Category'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));

const DashboardLayout = (props: any) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const md = useMediaQuery('(min-width:900px)');
    const handleDrawerToggle = () => {
        if(!md) {
            setMobileOpen(!mobileOpen);
        }
    };

    const drawerWidth = 300;
    const mddrawerWidth = 340;

    const drawer = (
        <div>
          <Toolbar />
          <SidebarLayout handleDrawerToggle={handleDrawerToggle} />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const PrivateRoutes = (
        <>
            <Route path='/dashboard' element={
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            } />
            <Route path='/categories' element={
                <PrivateRoute>
                    <Category />
                </PrivateRoute>
            } />
            <Route path='/appointments' element={
                <PrivateRoute>
                    <Appointment />
                </PrivateRoute>
            } />
            <Route path='/users' element={
                <PrivateRoute>
                    <UserList />
                </PrivateRoute>
            } />

            <Route path='/categories/:categoryId/sub-categories' element={
                <PrivateRoute>
                    <SubCategoryList />
                </PrivateRoute>
            } />

            <Route path='/categories/:categoryId/questions' element={
                <PrivateRoute>
                    <QuestionList />
                </PrivateRoute>
            } />

            <Route path='/appointments/:appointmentId/detail' element={
                <PrivateRoute>
                    <AppointmentDetail />
                </PrivateRoute>
            } />

            <Route path='/users/:userId/detail' element={
                <PrivateRoute>
                    <UserDetail />
                </PrivateRoute>
            } />
        </>
    )
    
  

    return (
        <>
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
            position="fixed"
            sx={{
                width: { md: `calc(100% - ${mddrawerWidth}px)` },
                ml: { md: `${mddrawerWidth}px` },
                display: {md: `none`}
            }}
            >
            <Toolbar>
                <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' } }}
                >
                <MenuIcon sx={{color: "rgb(81 81 81)"}}/>
                </IconButton>
            </Toolbar>
            </AppBar>
            <Box
            component="aside"
            sx={{ width: { md: `${mddrawerWidth}px` }, flexShrink: { md: 0 } }}
            aria-label="sidebar components"
            >
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                '& .MuiDrawer-paperone': {width: { md: `${mddrawerWidth}px` }},
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                className="sidebarFixed"
                variant="permanent"
                sx={{
                display: { xs: 'none', md: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                '& .MuiDrawer-paperone': {width: { md: `${mddrawerWidth}px` }},
                }}
                open
            >
                {drawer}
            </Drawer>
            </Box>
            <Box
            component="main"
            sx={{
                flexGrow: 1, pt: 2, pb: 2,
                width: { md: `calc(100% - ${mddrawerWidth}px)` },
                padding: {md: 0}
            }}
            >
            <Toolbar
                sx={{
                    display: {md: `none`}
                }}
            />
            <Box
                sx={{
                    background: "#FAFBFC"
                }}
            >
                <Box sx={{
                    minHeight: "100vh",
                    background: "#FAFBFC"
                }}>
                    <Box>
                        <Suspense fallback={<div>Loading...</div>}>

                            <Routes>
                                {PrivateRoutes}
                                {/* {PublicRoutes} */}
                            </Routes>
                        </ Suspense>                          
                    </Box>
                </Box>
            </Box>
            </Box>
        </Box>
        </>
    );
}


export default DashboardLayout;
