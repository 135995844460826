import {
    APPOINTMENT_REDUX,
    RESET
    } from '../../constants';
import { appointmentListContentDataModifiedObj } from '../../data/mappers/appointmentList';
import { AddAppointmentListContentAction, AddNewAppointmentToListContentAction, appointmentListState } from './types';


export const AddAppointmentListContent = (data: appointmentListState): AddAppointmentListContentAction => ({
    type: APPOINTMENT_REDUX.ADD_APPOINTMENT_LIST_CONTENT_DATA,
    data,
});

export const AddNewAppointmentToListContent = (data: appointmentListContentDataModifiedObj): AddNewAppointmentToListContentAction => ({
    type: APPOINTMENT_REDUX.ADD_NEW_APPOINTMENT_TO_LIST_CONTENT_DATA,
    data,
});

export const AppointmentResetAction = () => ({
    type: RESET
});
