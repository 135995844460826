import {
    Category_LIST_REDUX,
    RESET
    } from '../../constants';
import { AddCategoryListContentAction, UpdateActiveStatusCategoryAction, categoryListState } from './types';


export const AddCategoryListContent = (data: categoryListState): AddCategoryListContentAction => ({
    type: Category_LIST_REDUX.ADD_CATEGORY_LIST_CONTENT_DATA,
    data,
});


export const UpdateActiveStatusCategory = (data: string): UpdateActiveStatusCategoryAction => ({
    type: Category_LIST_REDUX.BLOCK_UNBLOCK,
    data,
});


export const CategoryReset = () => ({
    type: RESET
});
