import React from 'react'
// import LogoLarge from '../../icons/logoLarge'

export default function LeftBarContainer() {
  return (
    <div className='leftbarlogo__container'>
        <div className='glass__tab'>
          <img src="/images/pp-logo.svg" alt="pp-logo" />
        </div>
    </div>
  )
}
