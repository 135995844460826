import SignIn from './components/signIn/SignIn.Component';
import DashboardLayout from './components/layout/DashboardLayout';

import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import './styles/App.scss';
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, LinearProgress, Snackbar } from "@mui/material";
import { ShowErrorAlert, ShowSuccessAlert } from './store/alerts';
import { RootStateType } from './store';

import { Navigate } from "react-router";
import { createContext, 
    // useState, 
    useContext } from 'react';
import PrivacyPolicy from "./components/pages/publicPages/privacyPolicy";
import TermsAndCondition from './components/pages/publicPages/termsAndCondition';


import Cookies from 'js-cookie';
import FaqPage from './components/pages/publicPages/FaqPage';

// export const GetAccessToken = createContext({});
export const AccessTokenContext = createContext({});

function App() {
    // let [accessToken, setAccessToken] = useState("");

    let cookieAccessToken = Cookies.get('access_token');
    let cookieRefreshToken = Cookies.get('refresh_token');

    const dispatch = useDispatch();
    const alerts = useSelector((state: RootStateType) => state.alerts);


    const handleClose = () => {
        dispatch(ShowSuccessAlert({visible:false, message: ""}));
    }

    const handleErrorClose = () => {
        dispatch(ShowErrorAlert({visible:false, message: ""}));
    }

    const PublicRoutes = (
        <>
            <Route path='/privacy_policy' element={
                <PrivacyPolicy />
            } />

            <Route path='/terms_and_conditions' element={
                <TermsAndCondition />
            } />


            <Route path='/faq' element={
                <FaqPage />
            } />
        </>
    )

    return (
        <>
            {/* <GetAccessToken.Provider value={{accessToken, setAccessToken}}> */}
                <AccessTokenContext.Provider value={{cookieAccessToken, cookieRefreshToken}}>

                    {alerts.loader.visible ? <Box component={'div'} sx={{ width: '100%', position:'fixed', zIndex:"9999" }}>
                        <LinearProgress sx = {{
                            backgroundColor: 'var(--brand--font--link)'
                        }}/>
                    </Box> : ''}
                    <Snackbar className='red--whiteIcon' anchorOrigin={{ vertical : 'top', horizontal : 'center' }} open={alerts.success.visible} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%', color: '#fff', background:  '#5AAA77', fontWeight: 400}}>
                            {alerts.success.message}
                        </Alert>
                    </Snackbar>
                    <Snackbar className='red--whiteIcon' anchorOrigin={{ vertical : 'top', horizontal : 'center' }} open={alerts.error.visible} autoHideDuration={3000} onClose={handleErrorClose}>
                        <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%', color: '#fff', background: '#EB3751', fontWeight: 400 }}>
                            {alerts.error.message}
                        </Alert>
                    </Snackbar>
                    <Router>
                        <Routes>
                            <Route path='/' element={
                                <IsLoggedIn>
                                    <SignIn />
                                </IsLoggedIn>
                                }
                            />
                            <Route path='/admin/*' element={
                                <DashboardLayout />
                            } />
                            {PublicRoutes}
                        </Routes>
                    </Router>
                </AccessTokenContext.Provider>
            {/* </GetAccessToken.Provider> */}
        </>
    );
}


export const PrivateRoute = ({ children, ...rest }: any) => {

    let token:any = useContext(AccessTokenContext);
    let accessToken = token.cookieAccessToken
    if (!accessToken) {
        return <Navigate to={{ pathname: "/" }} />
    }
    return children;
}

export const IsLoggedIn = ({ children, ...rest }: any) => {
    let token:any = useContext(AccessTokenContext);
    let accessToken = token.cookieAccessToken

    if (
        accessToken
        &&
        typeof accessToken !== "undefined"
        &&
        accessToken?.length > 0
    ) {
      return <Navigate to={{ pathname: "/admin/dashboard/" }} />
    }
  
    return children;
  }


export default App;
